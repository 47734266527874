import React from "react";
const Accordion = ({ title, answer, isOpen, onToggle }) => {
  const [firstWord, ...restOfAnswer] = answer.split(" ");

  return (
    <div className="py-2">
      <button
        onClick={onToggle}
        className="flex justify-between items-start w-full text-left sm:items-center"
      >
        <span className="flex-1" style={{ color: "#006241" }}>
          {title}
        </span>
        <svg
          className="shrink-0 ml-2 sm:ml-8"
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
          style={{ fill: "#177245" }}
        >
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center transition duration-200 ease-out ${
              isOpen && "!rotate-180"
            }`}
          />
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center rotate-90 transition duration-200 ease-out ${
              isOpen && "!rotate-180"
            }`}
          />
        </svg>
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${
          isOpen
            ? "grid-rows-[1fr] opacity-100"
            : "grid-rows-[0fr] opacity-0  sm:text-lg md:text-xl"
        }`}
      >
        <div
          className="overflow-hidden text-left mt-2 indent-4  sm:text-lg md:text-xl"
          style={{ fontSize: "xx-larger" }}
        >
          <span
            style={{
              color: "#1e3932",
              fontSize: "xx-larger",
              fontFamily: "serif",
            }}
          >
            {firstWord}
          </span>{" "}
          {restOfAnswer.join(" ")}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
